import styled from '@emotion/styled/macro';
import React from 'react';

import { ReactComponent } from '../assets/logo.svg';

export function Header() {
  return (
    <StyledHeader>
      <StyledLogo/>
    </StyledHeader>
  );
}

const StyledHeader = styled.header`
  display: flex;
  justify-content: center;
  padding: calc(1.25rem + env(safe-area-inset-top)) 0 1.25rem;
  color: white;
  background-color: var(--primary);
`;

const StyledLogo = styled(ReactComponent)`
  height: 2rem;
`;
