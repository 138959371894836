import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useMatch, useNavigate } from 'react-router';

type Props = {
  text: string,
  path: string,
  icon: IconDefinition
};

export function NavigationItem({ text, path, icon }: Props) {
  const navigate = useNavigate();
  const active = useMatch(path);

  return (
    <StyledItem active={Boolean(active)} onClick={() => navigate(path)}>
      <FontAwesomeIcon icon={icon} size="lg"/>
      <StyledText>
        {text}
      </StyledText>
    </StyledItem>
  );
}

const StyledItem = styled.button<{ active: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 3px;
  max-width: 3.75rem;
  color: var(--foreground);
  ${(props) => props.active && css`
    padding-top: 0;
    color: var(--secondary);
    border-top: 3px solid var(--secondary);
    opacity: 1;
  `};
`;

const StyledText = styled.span`
  padding-top: .45rem;
  font-size: .7rem;
  line-height: 1;
  transition: font-size .15s ease;
`;
