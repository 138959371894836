import 'normalize.css';
import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { environment } from './constants';
import { Root } from './pages/Root';
import { AuthProvider } from './states/auth';
import { ConsentProvider } from './states/consent';
import { OverlayProvider } from './states/overlays';
import { RemoteProvider } from './states/remote';
import { ThemeProvider } from './states/theme';
import { register, unregister } from './utils/serviceWorker';

render((
  <BrowserRouter>
    <AuthProvider>
      <RemoteProvider>
        <OverlayProvider>
          <ConsentProvider>
            <ThemeProvider>
              <Root/>
            </ThemeProvider>
          </ConsentProvider>
        </OverlayProvider>
      </RemoteProvider>
    </AuthProvider>
  </BrowserRouter>
), document.getElementById('root'));

if (environment.worker) {
  register();
} else {
  unregister();
}
