export function range(min: number, max: number) {
  let result: number[] = [];

  for (let i = min; i < max; i++) {
    result = [
      ...result,
      i
    ];
  }

  return result;
}
