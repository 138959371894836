import styled from '@emotion/styled/macro';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as Code } from '../assets/qr.svg';
import { Button } from '../components/form/Button';
import { Popup } from '../components/overlay/Popup';
import { Page } from '../components/Page';
import { Scanner } from '../overlays/Scanner';
import { useAuth } from '../states/auth';
import { useOverlays } from '../states/overlays';
import { parseToken } from '../utils/parseToken';

export function Connect() {
  const navigate = useNavigate();
  const [, { addToken }] = useAuth();
  const [, { push, pop }] = useOverlays();

  const parseUrl = async (data: string) => {
    await pop();

    const token = parseToken(data);

    if (!token) {
      return push((
        <Popup title="Attention" actions={[]}>
          Invalid QR code
        </Popup>
      ));
    }

    await addToken(token);

    navigate({
      pathname: window.location.pathname
    });
  };

  return (
    <Page>
      <StyledCenter>
        <Code/>
        <StyledText>
          You are not connected to a TV. Please scan the QR code.
        </StyledText>
        <StyledButton
          content="Scan"
          size={2}
          onClick={push.c((
            <Scanner setResult={parseUrl}/>
          ))}
        />
      </StyledCenter>
    </Page>
  );
}

const StyledCenter = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledText = styled.div`
  max-width: 15rem;
  margin: 2rem 0 1.5rem;
  color: var(--foreground);
  text-align: center;
`;

const StyledButton = styled(Button)`
  color: white;
  background-color: var(--primary);
`;
