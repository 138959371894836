import styled from '@emotion/styled/macro';
import React, { useState } from 'react';

import { Command, Direction, directions } from '../../constants';
import { useRemote } from '../../states/remote';

import { RemoteButton } from './RemoteButton';

type Props = {
  disabled?: boolean,
  loading?: boolean
};

export function RemoteControl({ disabled = false, loading }: Props) {
  const [, { command }] = useRemote();
  const [direction, setDirection] = useState<Direction>();

  return (
    <StyledWrapper disabled={disabled}>
      <StyledControl direction={direction}>
        <RemoteButton
          direction={Direction.Up}
          wide={true}
          setDirection={setDirection}
          onClick={command.c(Command.CursorUp)}
        />
        <RemoteButton direction={Direction.Left} setDirection={setDirection} onClick={command.c(Command.CursorLeft)}/>
        <RemoteButton
          direction={Direction.None}
          setDirection={setDirection}
          onClick={command.c(Command.Ok)}
          loading={loading}
        />
        <RemoteButton direction={Direction.Right} setDirection={setDirection} onClick={command.c(Command.CursorRight)}/>
        <RemoteButton
          direction={Direction.Down}
          wide={true}
          setDirection={setDirection}
          onClick={command.c(Command.CursorDown)}
        />
      </StyledControl>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div<{ disabled: boolean }>`
  width: 11.5rem;
  perspective: 1000px;
  opacity: ${(props) => props.disabled ? '.5' : '1'};
  pointer-events: ${(props) => props.disabled ? 'none' : 'auto'};
  transition: opacity .25s ease;
`;

const StyledControl = styled.div<{ direction?: Direction }>`
  display: grid;
  grid-template: repeat(3, 1fr) / repeat(3, 1fr);
  width: 100%;
  background-color: var(--background);
  border-radius: 2.5rem;
  box-shadow: 0 .25rem .25rem 0 var(--shadow), 0 .5rem 1.25rem 0 var(--shadow);
  transition: box-shadow .15s ease, transform .15s ease;
  ${(props) => props.direction !== undefined && directions[props.direction]}
`;
