import styled from '@emotion/styled/macro';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ComponentPropsWithoutRef, useRef } from 'react';
import shortid from 'shortid';

type Props = {
  icon: IconDefinition,
  on: string,
  off: string
};

export function Toggle({ icon, on, off, ...props }: Props & ComponentPropsWithoutRef<'input'>) {
  const ref = useRef(shortid());

  return (
    <StyledWrapper htmlFor={ref.current}>
      <StyledInput id={ref.current} type="checkbox" {...props}/>
      <StyledToggle on={on} off={off}>
        <FontAwesomeIcon icon={icon}/>
      </StyledToggle>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.label`
  display: flex;
  width: 6rem;
  height: 2.5rem;
  border-radius: 1.25rem;
  background-color: var(--background);
  box-shadow: 0 .25rem .25rem 0 var(--shadow), 0 .5rem 1.25rem 0 var(--shadow);
  overflow: hidden;
`;

const StyledInput = styled.input`
  width: 0;
  :checked ~ div {
    transform: translateX(calc(6rem - 100%));
  }
`;

const StyledToggle = styled.div<{ on: string, off: string }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  color: var(--background);
  background-color: var(--foreground);
  border: .25rem solid var(--background);
  border-radius: 50%;
  transition: transform .25s ease;
  ::before, ::after {
    display: block;
    position: absolute;
    top: calc(50% - .5em);
    font-size: .75rem;
    color: var(--foreground);
  }
  ::before {
    content: "${(props) => props.off}";
    left: -3rem;
  }
  ::after {
    content: "${(props) => props.on}";
    right: -3rem;
  }
`;
