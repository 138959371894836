import styled from '@emotion/styled/macro';
import React from 'react';

import { range } from '../utils/range';

type Props = {
  amount: number,
  current: number
};

export function Dots({ amount, current }: Props) {
  return (
    <StyledDots>
      {range(0, amount).map((i) => (
        <StyledDot key={i} current={current === i}/>
      ))}
    </StyledDots>
  )
}

const StyledDots = styled.div`
  display: flex;
`;

const StyledDot = styled.div<{ current: boolean }>`
  width: .5rem;
  height: .5rem;
  background-color: var(${(props) => props.current ? '--secondary' : '--background-dots'});
  border-radius: 50%;
  transition: background-color .25s ease;
  &:not(:first-of-type) {
    margin-left: .25rem;
  }
`;
