import { css, Global } from '@emotion/react';
import { parse } from 'query-string';
import React, { useEffect } from 'react';
import {Navigate, Route, Routes, useLocation, useNavigate} from 'react-router-dom';

import { CookieConsent } from '../components/CookieConsent';
import { Navigation } from '../components/Navigation';
import { Overlays } from '../components/overlay/Overlays';
import { useAuth } from '../states/auth';
import { useConsent } from '../states/consent';
import { useRemote } from '../states/remote';
import { useTheme } from '../states/theme';
import { getManifest } from '../utils/getManifest';

import { Remote } from './Remote';
import { Settings } from './Settings';

export function Root() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const [{ tokens, ignore }, { addToken, removeToken }] = useAuth();
  const [{ client }] = useRemote();
  const [{ consent }] = useConsent();
  const [{ dark }, { setTheme }] = useTheme();

  useEffect(() => {
    const manifest = document.querySelector<HTMLLinkElement>('#manifest-link');

    if (!manifest) {
      return;
    }

    const json = JSON.stringify(getManifest(tokens, dark));
    const blob = new Blob([json], {
      type: 'application/json'
    });

    manifest.setAttribute('href', URL.createObjectURL(blob));
  }, [tokens, dark]);

  useEffect(() => {
    const { t, d } = parse(search);

    if (typeof t === 'string' && !ignore.includes(t)) {
      void addToken(t);
    }

    if (typeof d === 'string') {
      void setTheme(d === '1');
    }

    navigate({
      pathname: window.location.pathname
    }, { replace: true });
  }, [search, navigate, ignore, addToken, setTheme]);

  useEffect(() => {
    if (!client) {
      return;
    }

    client.onStompError = (e) => {
      const message = e.headers.message || '';

      if (message.includes('AuthenticationCredentialsNotFoundException')) {
        void removeToken();
      }
    };
  }, [client, removeToken]);

  return (
    <>
      <Global
        styles={css`
          :root {
            --primary: #196498;
            --secondary: #439ac1;
            --foreground: black;
            --background: white;
            --navigation: white;
            --background-dots: lightgray;
            --page: #f8f9f9;
            --shadow: rgba(0, 0, 0, .1);
          }
          .dark {
            --primary: #196498;
            --secondary: #439ac1;
            --foreground: #bfd0de;
            --background: #3b4d59;
            --background-dots: #3b4d59;
            --navigation: #152a3b;
            --page: #151f28;
            --shadow: rgba(0, 0, 0, .25);
          }
          *, *::before, *:after {
            backface-visibility: hidden;
            box-sizing: border-box;
          }
          body {
            background-color: var(--primary);
            font-family: 'Open Sans', sans-serif;
            user-select: none;
            -webkit-tap-highlight-color: transparent;
          }
          button {
            text-align: start;
            :hover:not(:disabled) {
              cursor: pointer;
            }
          }
          select, button, input {
            padding: 0;
            background: none;
            border: none;
            font: inherit;
            outline: none;
            appearance: none !important;
          }
          h1, h2, h3, h4, h5, h6 {
            margin: 0;
          }
          img, svg {
            vertical-align: middle;
          }
        `}
      />
      <Routes>
        <Route path="/" element={<Remote/>}/>
        <Route path="/settings" element={<Settings/>}/>
        <Route path="*" element={<Navigate to="/"/>}/>
      </Routes>
      <Navigation/>
      <Overlays/>
      {!consent && (
        <CookieConsent/>
      )}
    </>
  );
}
