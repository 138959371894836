import { createDakpan } from 'dakpan';

type Props = {
  dark: boolean
};

export const [ThemeProvider, useTheme] = createDakpan<Props>(() => {
  const storage = localStorage.getItem('dark');
  const dark = storage === null || storage === '1';

  if (dark) {
    document.body.classList.add('dark');
  }

  return {
    dark
  }
})({
  setTheme: (dark: boolean) => () => {
    localStorage.setItem('dark', dark ? '1' : '0');

    if (!dark) {
      document.body.classList.remove('dark');
    } else {
      document.body.classList.add('dark');
    }

    return {
      dark
    };
  },
  toggleTheme: () => ({ dark }) => {
    localStorage.setItem('dark', dark ? '0' : '1');
    document.body.classList.toggle('dark');

    return {
      dark: !dark
    };
  }
});
