import { css, SerializedStyles } from '@emotion/react';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faCheck, faChevronDown, faChevronLeft, faChevronRight, faChevronUp } from '@fortawesome/pro-regular-svg-icons';

export enum Command {
  Power = 12,
  Mute = 13,
  VolumeUp = 16,
  VolumeDown = 17,
  ChannelUp = 32,
  ChannelDown = 33,
  Ok = 92,
  Back = 10,
  Home = 209,
  CursorUp = 88,
  CursorDown = 89,
  CursorLeft = 90,
  CursorRight = 91,
  ChannelGrid = 210,
  Settings = 144,
  Options = 64,
  Subtitles = 75,
  Guide = 204,
  Number0 = 0,
  Number1 = 1,
  Number2 = 2,
  Number3 = 3,
  Number4 = 4,
  Number5 = 5,
  Number6 = 6,
  Number7 = 7,
  Number8 = 8,
  Number9 = 9,
  Red = 109,
  Green = 110,
  Yellow = 111,
  Blue = 112,
  Volume = 1000,
  GetData = 1001,
  StopData = 1002,
  Restart = 1003,
  TvApiType = 1004,
  RoomNumber = 1005
}

export enum Direction {
  None,
  Up,
  Down,
  Left,
  Right
}

export enum ButtonMode {
  Default,
  Transparent,
  Blue
}

export enum APIType {
  TV = 0,
  JAPIT_ONLY_TV = 1,
  OLD_JAPIT = 2,
}

export const icons: Record<Direction, IconDefinition> = {
  [Direction.None]: faCheck,
  [Direction.Up]: faChevronUp,
  [Direction.Down]: faChevronDown,
  [Direction.Left]: faChevronLeft,
  [Direction.Right]: faChevronRight
};

export const directions: Record<Direction, SerializedStyles> = {
  [Direction.None]: css`
    box-shadow: 0 0 1rem 0 var(--shadow);
    transform: scale(.95);
  `,
  [Direction.Up]: css`
    box-shadow: 0 .5rem .5rem 0 var(--shadow), 0 .5rem 1rem 0 var(--shadow);
    transform: rotateX(15deg);
  `,
  [Direction.Down]: css`
    box-shadow: 0 0 1rem 0 var(--shadow);
    transform: rotateX(-10deg);
  `,
  [Direction.Left]: css`
    box-shadow: .25rem .25rem 1rem 0 var(--shadow);
    transform: rotateY(-15deg);
  `,
  [Direction.Right]: css`
    box-shadow: -.25rem .25rem 1rem 0 var(--shadow);
    transform: rotateY(15deg);
  `
};

export const buttons: Record<ButtonMode, SerializedStyles> = {
  [ButtonMode.Default]: css`
    color: var(--foreground);
    background-color: var(--background);
    box-shadow: 0 .25rem .25rem 0 var(--shadow), 0 .5rem 1.25rem 0 var(--shadow);
    :active {
      ${directions[Direction.None]};
    }
  `,
  [ButtonMode.Transparent]: css`
    color: var(--primary);
    :active {
      transform: scale(.95);
    }
  `,
  [ButtonMode.Blue]: css`
    color: white;
    background-color: var(--primary);
    box-shadow: 0 .25rem .25rem 0 var(--shadow), 0 .5rem 1.25rem 0 var(--shadow);
    :active {
      ${directions[Direction.None]};
    }
  `
};

export const tabbableQueries = [
  'input',
  'button',
  'select',
  'textarea',
  'a[href]',
  '*[tabindex]:not([data-focus])'
];

export const environment = require(`./environments/${process.env.REACT_APP_ENV || 'development'}`).default;
