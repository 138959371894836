import styled from '@emotion/styled/macro';
import { faCheck, faTv } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { Button } from '../components/form/Button';
import { Modal } from "../components/overlay/Modal";
import { Popup } from '../components/overlay/Popup';
import { ButtonMode } from '../constants';
import { useAuth } from '../states/auth';
import { useOverlays } from '../states/overlays';
import { parseToken } from '../utils/parseToken';

import { Scanner } from './Scanner';

export function Devices() {
  const [, { push, pop }] = useOverlays();
  const [{ tokens, current, roomNumbers }, { addToken, setCurrent }] = useAuth();

  const setResult = async (data: string) => {
    await pop();

    const token = parseToken(data);

    if (!token) {
      return push((
        <Popup title="Attention" actions={[]}>
          Invalid QR code
        </Popup>
      ));
    }

    await addToken(token);
  }

  return (
    <Modal title="Rooms">
      <StyledList>
        {tokens.map((token, i) => (
          <StyledDevice key={i} onClick={setCurrent.c(token)}>
            <div>
              <StyledTv icon={faTv}/>
              Room { roomNumbers[i] }
            </div>
            {current === i && (
              <StyledCheck icon={faCheck} size="lg"/>
            )}
          </StyledDevice>
        ))}
      </StyledList>
      <StyledFooter>
        Want to connect to a different TV?
        <StyledButton
          content="Scan QR code"
          mode={ButtonMode.Blue}
          size={3}
          onClick={push.c((
            <Scanner setResult={setResult}/>
          ))}
        />
      </StyledFooter>
    </Modal>
  );
}

const StyledList = styled.div`
  margin: 0 2rem;
`;

const StyledDevice = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3rem;
  color: var(--foreground);
  border-bottom: 1px solid var(--background);
`;

const StyledTv = styled(FontAwesomeIcon)`
  margin-right: 1rem;
`;

const StyledCheck = styled(FontAwesomeIcon)`
  color: var(--secondary);
`;

const StyledFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem 1rem;
  color: var(--foreground);
`;

const StyledButton = styled(Button)`
  margin-top: 1.5rem;
`;
