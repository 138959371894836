import styled from '@emotion/styled/macro';
import {
  faChevronDown,
  faClosedCaptioning,
  faCog,
  faHomeAlt,
  faLifeRing,
  faListUl,
  faLongArrowLeft,
  faPlus,
  faPowerOff,
  faVolume,
  faVolumeMute,
  faSlash,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StompSubscription } from '@stomp/stompjs';
import React, { useEffect, useState } from 'react';
import AwesomeSlider from 'react-awesome-slider';

import { Dots } from '../components/Dots';
import { Button } from '../components/form/Button';
import { ColoredButton } from '../components/form/ColoredButton';
import { Page } from '../components/Page';
import { RangeControl } from '../components/remote/RangeControl';
import { RemoteControl } from '../components/remote/RemoteControl';
import {APIType, Command} from '../constants';
import useUnload from '../hooks/useUnload';
import { Devices } from '../overlays/Devices';
import { useAuth } from '../states/auth';
import { useOverlays } from '../states/overlays';
import { useRemote } from '../states/remote';
import { range } from '../utils/range';

import { Connect } from './Connect';
import 'react-awesome-slider/dist/styles.css';
import '../assets/remote.css';

export function Remote() {
  const [{ current, tokens, roomNumbers }, { removeToken, updateRoomNumbers }] = useAuth();
  const [{ client }, { connect, disconnect, command }] = useRemote();
  const [, { push }] = useOverlays();
  const [slide, setSlide] = useState(0);
  const [volume, setVolume] = useState(-1);
  const [muted, setMuted] = useState(false);
  const [power, setPower] = useState(false);
  const [roomNumber, setRoomNumber] = useState("...");
  const [tvOffline, setOffline] = useState(false);
  const [jointSpaceAPI, setJointSpaceAPI] = useState(false);

  //const [isSwiping, setIsSwiping] = useState(false);
  // states don't update instantly, I don't know enough react to figure out a better solution but it works
  let isSwiping = false;
  const setIsSwiping = (b) => { isSwiping=b; }

  useUnload(e => { // the method that will be used for both add and remove event
       e.preventDefault();
       e.returnValue = false;
    });


  useEffect(() => {
    if (current === -1) {
      return;
    }

    void connect(tokens[current]);

    return () => void disconnect();
  }, [current, tokens, connect, disconnect]);

  useEffect(() => {
    if (!client) {
      return;
    }

    let state: StompSubscription | undefined;
    let disconnect: StompSubscription | undefined;

    client.onConnect = () => {
      let messages = 0;
      state = client.subscribe('/user/remote/state', ({ body }) => {
        let items = JSON.parse(body);

        if (!Array.isArray(items)) {
          items = [items];
        }

        for (const item of items as { type: Command, value: string }[]) {
          if (item.type === Command.Volume) {
            setVolume( parseInt(item.value, 10));
          } else if (item.type === Command.Mute) {
            setMuted(item.value === 'ON');
          } else if (item.type === Command.Power) {
            setPower(item.value === 'ON');
            setOffline(item.value === 'OFFLINE');
          } else if (item.type === Command.RoomNumber) {
            setRoomNumber(item.value);
            roomNumbers[current] = item.value;
            updateRoomNumbers(roomNumbers);
            if (messages === 0) {
               command(Command.GetData, 0);
            }
          } else if (item.type === Command.TvApiType) {
            setJointSpaceAPI(parseInt(item.value) === APIType.OLD_JAPIT);
          }
        }
        messages = Math.max(++messages, 1);
      });

      disconnect = client.subscribe('/user/remote/disconnect', () => void removeToken());
    };

    return () => {
      state?.unsubscribe();
      disconnect?.unsubscribe();
      setVolume(-1);
    };
  }, [client, command, removeToken, roomNumbers, current, updateRoomNumbers]);

  if (current === -1) {
    return (
      <Connect/>
    );
  }

  return (
    <StyledPage>
      <StyledCentered>
        <StyledLabel
          onClick={push.c((
            <Devices/>
          ))}
        >
          Room {roomNumber} <FontAwesomeIcon icon={faChevronDown}/>
        </StyledLabel>
      </StyledCentered>

      <Slider bullets={false}
              onTransitionStart={()=>{setIsSwiping(true)}}
                     media={[
        {
          className: "remote-page",
          children: <>
          <StyledCentered>
            <StyledButton
              content={faPowerOff}
              customColor={power?'green':(tvOffline?'gray':'red')}
              onClick={()=>{if(!isSwiping) command(Command.Power)}}
              disabled={!client?.connected || tvOffline}
            />
            {tvOffline? <OverlayIcon><FontAwesomeIcon icon={faSlash}/></OverlayIcon>:<></>}
          </StyledCentered>

          <StyledControls>
            <RangeControl
              content={faVolume}
              ramp={60}
              value={volume}
              setValue={(value) => {if(!isSwiping) command(Command.Volume, value).then(() => setMuted(false))}}
              disabled={!power}
            />
            <RemoteControl disabled={!power} loading={!client?.connected}/>
            <RangeControl
              content="CH"
              setValue={(value) => {if(!isSwiping) command(value > 0 ? Command.ChannelUp : Command.ChannelDown)}}
              disabled={!power}
            />
          </StyledControls>
          <StyledSpaced>
            <Button content={muted ? faVolumeMute : faVolume} onClick={()=>{if(!isSwiping) command(Command.Mute)}} disabled={!power}/>
            <Button content={faLongArrowLeft} onClick={()=>{if(!isSwiping) command(Command.Back)}} disabled={!power}/>
            <Button content={faHomeAlt} onClick={()=>{if(!isSwiping) command(Command.Home)}} disabled={!power}/>
            <Button content={faListUl} onClick={()=>{if(!isSwiping) command(Command.ChannelGrid)}} disabled={!power} hidden={jointSpaceAPI}/>
          </StyledSpaced>
        </>
        },
        {
          className: "remote-page",
          children: <>
        <StyledCentered expand={true}>
          <StyledKeypad>
            {range(1, 10).map((i) => (
              <Button key={i} content={String(i)} onClick={()=>{if(!isSwiping) command(i as Command)}} disabled={!power}/>
            ))}
            <Button content={faLongArrowLeft} onClick={()=>{if(!isSwiping) command(Command.Back)}} disabled={!power}/>
            <Button content="0" onClick={()=>{if(!isSwiping) command(Command.Number0)}} disabled={!power}/>
            <Button content="OK" onClick={()=>{if(!isSwiping) command(Command.Ok)}} disabled={!power}/>
          </StyledKeypad>
        </StyledCentered>
        </>
        },
        {
          className: "remote-page",
          children: <>
        <StyledCentered expand={true}>
          <StyledActions>
            <ColoredButton content="A" color="#B64F49" onClick={()=>{if(!isSwiping) command(Command.Red)}} disabled={!power}/>
            <ColoredButton content="B" color="#7AC25B" onClick={()=>{if(!isSwiping) command(Command.Green)}} disabled={!power}/>
            <ColoredButton content="C" color="#F9E272" onClick={()=>{if(!isSwiping) command(Command.Yellow)}} disabled={!power}/>
            <ColoredButton content="D" color="#75BAFC" onClick={()=>{if(!isSwiping) command(Command.Blue)}} disabled={!power}/>
            <StyledAction hidden={jointSpaceAPI}>
              <Button content={faCog} onClick={()=>{if(!isSwiping) command(Command.Settings)}} disabled={!power}/>
              Settings
            </StyledAction>
            <StyledAction>
              <Button content={faPlus} onClick={()=>{if(!isSwiping) command(Command.Options)}} disabled={!power}/>
              Options
            </StyledAction>
            <StyledAction>
              <Button content={faClosedCaptioning} onClick={()=>{if(!isSwiping) command(Command.Subtitles)}} disabled={!power}/>
              Subtitles
            </StyledAction>
            <StyledAction hidden={jointSpaceAPI}>
              <Button content={faLifeRing} onClick={()=>{if(!isSwiping) command(Command.Guide)}} disabled={!power}/>
              TV Guide
            </StyledAction>
          </StyledActions>
        </StyledCentered>
        </>
        }
      ]}
                     buttons={false}
                     organicArrows={false}
                     onTransitionEnd={(e) => {setSlide(e.currentIndex);setIsSwiping(false);}}/>
      <StyledCentered>
        <Dots amount={3} current={slide}/>
      </StyledCentered>
    </StyledPage>
  );
}

const StyledPage = styled(Page)`
  padding-bottom: 1.5rem;
`;

const StyledSpaced = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledCentered = styled.div<{ expand?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => props.expand ? '100%' : 'auto'};
`;

const StyledControls = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1.5rem 0;
  transition: margin .25s ease;
  @media (min-height: 45rem) {
    margin: 3rem 0;
  }
`;

const Slider = styled(AwesomeSlider)`
  height:60vh;
`;

const StyledLabel = styled.span`
  margin-top: 1.5rem;
  color: var(--secondary);
  font-size: 1.25rem;
  transition: margin .25s ease;
  @media (min-height: 45rem) {
    margin: 2.5rem 0 1.5rem;
  }
`;

const StyledButton = styled(Button)<{ customColor?: string|null }>`
  color: ${(props) => {
    if(!props.customColor) return 'var(--foreground)';
    return props.customColor;
}};
`;

const StyledKeypad = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1.5rem;
`;

const StyledActions = styled(StyledKeypad)`
  grid-template-columns: 3.5rem 3.5rem;
  grid-gap: 3rem;
`;

const StyledAction = styled.div<{hidden?: boolean}>`
  display: ${(props) => props.hidden ? 'none' : 'flex'};
  flex-direction: column;
  align-items: center;
  color: var(--foreground);
  text-transform: uppercase;
  font-size: .85rem;
  white-space: nowrap;
  > *:first-child {
    margin-bottom: .5rem;
  }
`;

const OverlayIcon = styled.div`
  position: absolute;
  font-size: x-large;
  color: gray;
`;