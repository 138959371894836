/* eslint-disable @typescript-eslint/camelcase */
export function getManifest(tokens: string[], dark: boolean) {
  if (!tokens) {
    return;
  }

  const url = `${window.location.href.replace(window.location.pathname, '').replace(window.location.search, '')}`;

  return {
    name: 'GuestConnect',
    short_name: 'GuestConnect',
    theme_color: '#0079c2',
    background_color: '#0079c2',
    display: 'standalone',
    orientation: 'portrait',
    start_url: `${url}?t=${tokens[0]}&d=${dark ? '1' : '0'}`,
    icons: [
      {
        src: `${url}icons/icon-72x72.png`,
        sizes: '72x72',
        type: 'image/png'
      },
      {
        src: `${url}icons/icon-96x96.png`,
        sizes: '96x96',
        type: 'image/png'
      },
      {
        src: `${url}icons/icon-128x128.png`,
        sizes: '128x128',
        type: 'image/png'
      },
      {
        src: `${url}icons/icon-144x144.png`,
        sizes: '144x144',
        type: 'image/png'
      },
      {
        src: `${url}icons/icon-152x152.png`,
        sizes: '152x152',
        type: 'image/png'
      },
      {
        src: `${url}icons/icon-192x192.png`,
        sizes: '192x192',
        type: 'image/png'
      },
      {
        src: `${url}icons/icon-384x384.png`,
        sizes: '384x384',
        type: 'image/png'
      },
      {
        src: `${url}icons/icon-512x512.png`,
        sizes: '512x512',
        type: 'image/png'
      }
    ],
    splash_pages: null
  };
}
