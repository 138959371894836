import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';

import { OverlayProvider } from '../../contexts/overlay';
import { useContainer } from '../../hooks/useContainer';
import { useHide } from '../../hooks/useHide';
import { useScrollLock } from '../../hooks/useScrollLock';
import { useOverlays } from '../../states/overlays';

export function Overlays() {
  const [{ overlays }] = useOverlays();
  const [hide, unhide] = useHide();
  const [lock, unlock] = useScrollLock();
  const container = useContainer('remote-overlay');

  useEffect(() => {
    if (!overlays.length) {
      return;
    }

    hide(document.body, '#remote-overlay');
    lock();

    return () => {
      unhide();
      unlock();
    };
  }, [overlays, hide, unhide, lock, unlock]);

  return !overlays.length || !container ? null : createPortal((
    <div>
      {overlays.map((overlay) => (
        <OverlayProvider key={overlay.key} value={overlay}>
          {overlay.element}
        </OverlayProvider>
      ))}
    </div>
  ), container);
}
