import styled from '@emotion/styled/macro';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';

import { Direction, icons } from '../../constants';
import { useRamp } from '../../hooks/useRamp';

type Props = {
  direction: Direction,
  content?: IconDefinition | string,
  hold?: boolean,
  setDirection: (direction?: Direction) => void,
  onClick?: () => void
};

export function RangeButton({ direction, content, hold = false, setDirection, onClick }: Props) {
  const [active, handleDown, handleUp] = useRamp(hold, onClick);

  useEffect(() => {
    if (!active) {
      return;
    }

    setDirection(direction);

    return () => setDirection();
  }, [direction, active, setDirection]);

  return (
    <StyledButton
      onTouchStart={handleDown}
      onTouchEnd={handleUp}
      onContextMenu={(e) => e.preventDefault()}
    >
      {typeof content === 'string' ? content : (
        <FontAwesomeIcon icon={content || icons[direction]} size="lg"/>
      )}
    </StyledButton>
  );
}

const StyledButton = styled.button`
  flex: 1;
  color: var(--foreground);
  text-align: center;
  z-index: 1;
`;
