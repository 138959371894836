import { keyframes } from '@emotion/react';
import styled from '@emotion/styled/macro';
import React, { ReactNode } from 'react';

import { ButtonMode } from '../../constants';
import { useOverlay } from '../../contexts/overlay';
import { useOverlays } from '../../states/overlays';
import { Focus } from '../Focus';
import { Button } from '../form/Button';

type Props = {
  children?: ReactNode,
  title: string,
  actions: {
    text: string,
    onClick: () => void,
    mode?: ButtonMode
  }[]
};

export function Popup({ children, title, actions }: Props) {
  const overlay = useOverlay();
  const [{ overlays }, { pop, remove }] = useOverlays();

  return (
    <>
      <StyledBackdrop open={overlay.open} onAnimationEnd={() => !overlay.open && remove(overlay.key)}/>
      <Focus enabled={overlays.indexOf(overlay) === overlays.length - 1} onEscape={pop}>
        <StyledOverlay open={overlay.open}>
          <StyledTitle>
            {title}
          </StyledTitle>
          {children}
          <StyledActions>
            {actions.map(({ text, onClick, mode }, i) => (
              <StyledAction key={i} content={text} mode={mode} size={2} onClick={onClick}/>
            ))}
          </StyledActions>
        </StyledOverlay>
      </Focus>
    </>
  );
}

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const fadeOut = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;

const StyledBackdrop = styled.div<{ open: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  animation: ${(props) => props.open ? fadeIn : fadeOut} .25s ease;
  z-index: 100;
`;

const jumpIn = keyframes`
  from { opacity: 0; transform: translate(-50%, -50%) scale(.95); }
  to { opacity: 1; transform: translate(-50%, -50%); }
`;

const jumpOut = keyframes`
  from { opacity: 1; transform: translate(-50%, -50%); }
  to { opacity: 0; transform: translate(-50%, -50%) scale(.95); }
`;

const StyledOverlay = styled.div<{ open: boolean }>`
  position: fixed;
  top: 35%;
  left: 50%;
  width: calc(100% - 2rem);
  padding: 1.5rem;
  color: var(--foreground);
  background-color: var(--page);
  border-radius: 1rem;
  box-shadow: 0 .25rem .25rem 0 var(--shadow), 0 .5rem 1.25rem 0 var(--shadow);
  animation: ${(props) => props.open ? jumpIn : jumpOut} .25s ease forwards;
`;

const StyledTitle = styled.span`
  display: block;
  margin-bottom: 1rem;
  color: var(--foreground);
  text-align: center;
`;

const StyledActions = styled.div`
  display: flex;
  margin-top: 1rem;
`;

const StyledAction = styled(Button)`
  &:not(:last-of-type) {
    margin-right: 1rem;
  }
`;
