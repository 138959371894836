import { createDakpan } from 'dakpan';
import { ReactNode } from 'react';
import shortid from 'shortid';

import { Overlay, OverlayProps } from '../types';

type State = {
  overlays: Overlay[]
};

export const [OverlayProvider, useOverlays] = createDakpan<State>({
  overlays: []
})({
  push: (element: ReactNode, props?: OverlayProps) => (state) => ({
    ...state,
    overlays: [
      ...state.overlays,
      {
        key: shortid(),
        open: true,
        element,
        ...props
      }
    ]
  }),
  pop: () => (state) => ({
    ...state,
    overlays: state.overlays.map((overlay, i) => {
      if (i !== state.overlays.length - 1) {
        return overlay;
      }

      overlay.onDismiss?.();

      return {
        ...overlay,
        open: false
      };
    })
  }),
  remove: (key: string) => (state) => ({
    ...state,
    overlays: state.overlays.filter((overlay) => overlay.key !== key)
  }),
  reset: () => (state) => ({
    ...state,
    overlays: []
  })
});
