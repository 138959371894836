import styled from '@emotion/styled/macro';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ComponentPropsWithoutRef } from 'react';

import { ButtonMode, buttons } from '../../constants';

type Props = {
  content: IconDefinition | string,
  mode?: ButtonMode,
  size?: number
};

export function Button({ content, mode = ButtonMode.Default, size = 1, hidden = false, ...props }: Props & ComponentPropsWithoutRef<'button'>) {
  if (hidden) return (<></>);
  return (
    <StyledButton
      mode={mode}
      size={size}
      onTouchStart={() => navigator.vibrate?.(30)}
      onTouchEnd={() => navigator.vibrate?.(30)}
      {...props}
    >
      {typeof content === 'string' ? content : (
        <FontAwesomeIcon icon={content} size="lg"/>
      )}
    </StyledButton>
  );
}

const StyledButton = styled.button<{ mode: ButtonMode, size: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => 3.5 * props.size}rem;
  height: 3.5rem;
  border-radius: 1rem;
  transition: box-shadow .15s ease, transform .15s ease, opacity .25s ease;
  ${(props) => buttons[props.mode]};
  :disabled {
    opacity: .5;
    pointer-events: none;
  }
`;
