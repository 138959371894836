import styled from '@emotion/styled/macro';
import React, { useCallback, useEffect, useRef, useState } from 'react';
// eslint-disable-next-line import/no-unresolved,import/no-webpack-loader-syntax
import worker from 'workerize-loader!../workers/qr';

import { Modal } from '../components/overlay/Modal';

const { scanQr } = worker();

type Props = {
  setResult: (result: string) => void
};

export function Scanner({ setResult }: Props) {
  const ref = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [error, setError] = useState(false);
  const scanCode = useCallback(() => {
    if (!ref.current || !canvasRef.current) {
      return;
    }

    const width = canvasRef.current.width = ref.current.videoWidth;
    const height = canvasRef.current.height = ref.current.videoHeight;

    const context = canvasRef.current.getContext('2d');

    if (!context) {
      return;
    }

    context.drawImage(ref.current, 0, 0, width, height);

    const data = context.getImageData(0, 0, width - 1, height - 1);

    scanQr(data).then((data) => {
      if (data) {
        return setResult(data.data)
      }

      requestAnimationFrame(scanCode);
    });
  }, [canvasRef, setResult]);

  useEffect(() => {
    if ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices) {
      let media: MediaStream | undefined;

      navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: 'environment'
        }
      }).then((stream) => {
        if (!ref.current) {
          return;
        }

        media = stream;

        ref.current.srcObject = stream;
        ref.current.play();
        requestAnimationFrame(scanCode);
      }).catch(() => setError(true));

      return () => media?.getTracks().forEach((track) => track.stop());
    }
  }, [ref, scanCode, setError]);

  return (
    <Modal title="Scan a code">
      <StyledContent>
        {error ? (
          <span>
            Unable to access camera
          </span>
        ) : (
          <>
            <StyledVideo ref={ref} playsInline={true}/>
            <canvas ref={canvasRef} hidden={true}/>
          </>
        )}
      </StyledContent>
    </Modal>
  );
}

const StyledContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--foreground);
  overflow: hidden;
`;

const StyledVideo = styled.video`
  flex: 0 0 100%;
`;
