import { keyframes } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactNode } from 'react';

import { useOverlay } from '../../contexts/overlay';
import { useOverlays } from '../../states/overlays';
import { Focus } from '../Focus';

type Props = {
  children?: ReactNode,
  title: string
};

export function Modal({ children, title }: Props) {
  const overlay = useOverlay();
  const [{ overlays }, { pop, remove }] = useOverlays();

  return (
    <Focus enabled={overlays.indexOf(overlay) === overlays.length - 1} onEscape={pop}>
      <StyledOverlay open={overlay.open} onAnimationEnd={() => !overlay.open && remove(overlay.key)}>
        <StyledHeader>
          <StyledButton onClick={pop}>
            <FontAwesomeIcon icon={faTimes} size="lg"/>
          </StyledButton>
          {title}
        </StyledHeader>
        {children}
      </StyledOverlay>
    </Focus>
  );
}

const slideIn = keyframes`
  from { transform: translateY(100%); }
  to { transform: none; }
`;

const slideOut = keyframes`
  from { transform: none; }
  to { transform: translateY(100%); }
`;

const StyledOverlay = styled.div<{ open: boolean }>`
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--page);
  box-shadow: 0 0 2rem 0 var(--shadow);
  animation: ${(props) => props.open ? slideIn : slideOut} .25s ease-out forwards;
`;

const StyledHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: calc(1.5rem + env(safe-area-inset-top)) 1.5rem 1.5rem;
  color: var(--foreground);
`;

const StyledButton = styled.button`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  width: 2rem;
  height: 2rem;
  color: var(--secondary);
  border-radius: 50%;
`;
