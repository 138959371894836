export function parseToken(data: string) {
  try {
    const url = new URL(data);
    const token = url.searchParams.get('t');

    if (!token) {
      return;
    }

    return token;
  } catch {
  }
}
