import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';

import { Direction, icons } from '../../constants';
import { useRamp } from '../../hooks/useRamp';

type Props = {
  direction: Direction,
  wide?: boolean,
  setDirection: (direction?: Direction) => void,
  onClick: () => void,
  loading?: boolean
};

export function RemoteButton({ direction, wide = false, setDirection, onClick, loading = false }: Props) {
  const [active, handleDown, handleUp] = useRamp(true, onClick);

  useEffect(() => {
    if (!active) {
      return;
    }

    setDirection(direction);

    return () => setDirection();
  }, [direction, active, setDirection]);

  return (
    <StyledButton
      wide={wide}
      onTouchStart={handleDown}
      onTouchEnd={handleUp}
      onContextMenu={(e) => e.preventDefault()}
    >
      <StyledContent>
        {loading ? (
          <FontAwesomeIcon icon={faSpinnerThird} size="2x" spin={true}/>
        ) : direction === Direction.None ? 'OK' : (
          <FontAwesomeIcon icon={icons[direction]} size="lg"/>
        )}
      </StyledContent>
    </StyledButton>
  );
}

const StyledButton = styled.button<{ wide: boolean }>`
  position: relative;
  color: var(--foreground);
  ::after {
    content: "";
    display: block;
    padding-top: 100%;
  }
  ${(props) => props.wide && css`
    grid-column: span 3;
    margin: 0 calc(100% / 3);
  `};
`;

const StyledContent = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
