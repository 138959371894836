import styled from '@emotion/styled/macro';
import React, { ComponentPropsWithoutRef } from 'react';

import { Header } from './Header';

export function Page({ children, ...props }: ComponentPropsWithoutRef<'main'>) {
  return (
    <StyledPage {...props}>
      <Header/>
      {children}
    </StyledPage>
  );
}

const StyledPage = styled.main`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 4.5rem - env(safe-area-inset-bottom));
  margin: 0 auto calc(4.5rem + env(safe-area-inset-bottom));
  background-color: var(--page);
  z-index: 1;
  ::after {
    content: "";
    position: fixed;
    display: block;
    top: 50%;
    left: 0;
    width: 100%;
    height: 50%;
    background-color: var(--page);
    z-index: -1;
  }
`;
