import styled from '@emotion/styled/macro';
import { faMoon } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';

import { Toggle } from '../components/form/Toggle';
import { Page } from '../components/Page';
import { useTheme } from '../states/theme';

export function Settings() {
  const [{ dark }, { toggleTheme }] = useTheme();

  return (
    <Page>
      <StyledRow>
        Theme
        <Toggle
          icon={faMoon}
          on="Light"
          off="Dark"
          checked={dark}
          onChange={toggleTheme}
        />
      </StyledRow>
    </Page>
  );
}

const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.5rem;
  color: var(--foreground);
`;
