import { Client } from '@stomp/stompjs';
import { createDakpan } from 'dakpan';
import SockJS from 'sockjs-client';

import { Command, environment } from '../constants';

type State = {
  client?: Client
};

export const [RemoteProvider, useRemote] = createDakpan<State>({})({
  connect: (token: string) => () => {
    const client = new Client({
      webSocketFactory: () => new SockJS(`${environment.api}ws/`),
      connectHeaders: {
        CONNECTION_TYPE: 'REMOTE',
        TOKEN: token
      },
      debug: console.log
    });

    client.activate();

    return {
      client
    };
  },
  command: (command: Command, value?: unknown) => ({ client }) => {
    if (!client?.connected) {
      return undefined;
    }

    client.publish({
      destination: '/app/remote/fixCommand',
      body: JSON.stringify({
        type: command,
        value
      })
    });
  },
  disconnect: () => ({ client }) => {
    client?.deactivate();

    return {};
  }
});
