import styled from '@emotion/styled/macro';
import { faCog, faMouseAlt } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';

import { NavigationItem } from './NavigationItem';

export function Navigation() {
  return (
    <StyledNavigation data-fixed="">
      <NavigationItem
        text="TV Remote"
        path="/"
        icon={faMouseAlt}
      />
      <NavigationItem
        text="Settings"
        path="/settings"
        icon={faCog}
      />
    </StyledNavigation>
  );
}

const StyledNavigation = styled.nav`
  position: fixed;
  display: flex;
  justify-content: space-around;
  width: 100%;
  bottom: 0;
  left: 0;
  height: calc(4.5rem + env(safe-area-inset-bottom));
  padding-bottom: env(safe-area-inset-bottom);
  background-color: var(--navigation);
  box-shadow: 0 0 2rem 0 var(--shadow);
  z-index: 50;
`;
