import { useRef, useState } from 'react';

export function useRamp(hold: boolean, onClick?: () => void): [boolean, () => void, () => void] {
  const countRef = useRef(0);
  const timeoutRef = useRef(0);
  const [holding, setHolding] = useState(false);

  const handleDown = () => {
    navigator.vibrate?.(30);
    setHolding(true);
    handleUpdate();
  };

  const handleUp = () => {
    navigator.vibrate?.(30);
    setHolding(false);
    countRef.current = 0;
    clearTimeout(timeoutRef.current);
  };

  const handleUpdate = () => {
    onClick?.();
    countRef.current++;

    if (hold) {
      timeoutRef.current = setTimeout(handleUpdate, 250 - Math.min(countRef.current ** 2, 225)) as any;
    }
  };

  return [holding, handleDown, handleUp];
}
