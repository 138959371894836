import { createDakpan } from 'dakpan';

export const [ConsentProvider, useConsent] = createDakpan({
  consent: Boolean(localStorage.getItem('cookie-consent'))
})({
  setConsent: () => () => {
    localStorage.setItem('cookie-consent', '1');

    return {
      consent: true
    }
  }
});
