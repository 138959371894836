import { createContext, useContext } from 'react';

import { Overlay } from '../types';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const context = createContext<Overlay>(undefined!);

export const OverlayProvider = context.Provider;

export function useOverlay() {
  return useContext(context);
}
