import styled from '@emotion/styled';
import React, { ReactNode, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { tabbableQueries } from '../constants';
import { useContainer } from '../hooks/useContainer';
import { useKey } from '../hooks/useKey';

type Props = {
  children?: ReactNode,
  enabled: boolean,
  restore?: boolean,
  onEscape?: () => void
};

export function Focus({ children, enabled, restore, onEscape }: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const mounted = useRef(true);
  const [last, setLast] = useState<HTMLElement>();
  const container = useContainer('seatr-focus');

  useLayoutEffect(() => {
    if (enabled) {
      setLast(document.activeElement as HTMLElement || undefined);
      focusEdge(true);
    }
  }, [enabled]);

  useLayoutEffect(() => () => {
    mounted.current = false;
  }, []);

  useEffect(() => {
    if (restore && !enabled && last) {
      last.focus();
    }

    return () => {
      if (restore && !mounted.current && last) {
        last.focus();
      }
    };
  }, [restore, enabled, last]);

  useKey('Escape', () => enabled && onEscape?.(), [onEscape, enabled]);

  const focusEdge = (first: boolean) => {
    const tabbable = Array.from(ref.current?.querySelectorAll<HTMLElement>(tabbableQueries.join(',')) || []).filter((e) => e.tabIndex >= 0);

    if (!tabbable.length) {
      return;
    }

    tabbable[first ? 0 : tabbable.length - 1].focus();
  };

  return (
    <>
      {enabled && container && createPortal((
        <StyledBackdrop onClick={() => onEscape?.()}/>
      ), container)}
      <StyledFocus ref={ref}>
        {enabled && (
          <div tabIndex={0} onFocus={() => focusEdge(false)} data-focus=""/>
        )}
        {children}
        {enabled && (
          <div tabIndex={0} onFocus={() => focusEdge(true)} data-focus=""/>
        )}
      </StyledFocus>
    </>
  );
}

const StyledBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
`;

const StyledFocus = styled.div`
  position: relative;
  z-index: 10001;
`;
