import styled from '@emotion/styled/macro';
import React, { ComponentPropsWithoutRef } from 'react';

import { Button } from './Button';

type Props = {
  content: string,
  color: string
};

export function ColoredButton({ color, ...props }: Props & ComponentPropsWithoutRef<'button'>) {
  return (
    <StyledButton color={color} {...props}/>
  );
}

const StyledButton = styled(Button)<{ color: string }>`
  height: 1rem;
  color: black;
  background-color: ${(props) => props.color};
  border-radius: .25rem;
  font-size: .75rem;
`;
