import styled from '@emotion/styled/macro';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';

import { Direction, directions } from '../../constants';

import { RangeButton } from './RangeButton';

type Props = {
  content: IconDefinition | string,
  ramp?: number,
  value?: number,
  setValue: (value: number) => void,
  disabled?: boolean
};

export function RangeControl({ content, ramp, value: override, setValue: callback, disabled = false }: Props) {
  const ref = useRef(callback);
  const overridedRef = useRef(false);
  const [value, setValue] = useState(0);
  const [direction, setDirection] = useState<Direction>();

  ref.current = callback;

  useEffect(() => {
    if (overridedRef.current) {
      overridedRef.current = false;

      return;
    }

    if (value || ramp) {
      ref.current(value);
    }
  }, [value, ramp]);

  useEffect(() => {
    if (override !== undefined) {
      overridedRef.current = true;
      setValue(override);
    }
  }, [override, setValue]);

  return (
    <StyledWrapper disabled={disabled}>
      <StyledControl value={ramp ? value / ramp * 100 : 0} direction={direction}>
        <RangeButton
          direction={Direction.Up}
          hold={Boolean(ramp)}
          setDirection={setDirection}
          onClick={() => setValue((v) => ramp ? Math.min(ramp, v + 1) : Math.abs(v + 1))}
        />
        <StyledCenter>
          {typeof content === 'string' ? content : (
            <FontAwesomeIcon icon={content} size="lg"/>
          )}
        </StyledCenter>
        <RangeButton
          direction={Direction.Down}
          hold={Boolean(ramp)}
          setDirection={setDirection}
          onClick={() => setValue((v) => ramp ? Math.max(0, v - 1) : -Math.abs(v - 1))}
        />
      </StyledControl>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div<{ disabled: boolean }>`
  width: 3.5rem;
  perspective: 500px;
  opacity: ${(props) => props.disabled ? '.5' : '1'};
  pointer-events: ${(props) => props.disabled ? 'none' : 'auto'};
  transition: opacity .25s ease;
`;

const StyledControl = styled.div<{ value: number, direction?: Direction }>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  color: var(--foreground);
  background-color: var(--background);
  border-radius: 1rem;
  box-shadow: 0 .25rem .25rem 0 var(--shadow), 0 .5rem 1.25rem 0 var(--shadow);
  transition: box-shadow .15s ease, transform .15s ease;
  overflow: hidden;
  ::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: ${(props) => props.value}%;
    background-color: var(--secondary);
    transition: height .1s ease;
  }
  ${(props) => props.direction !== undefined && directions[props.direction]};
`;

const StyledCenter = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;
