import styled from '@emotion/styled/macro';
import React from 'react';

import { ButtonMode } from '../constants';
import { useConsent } from '../states/consent';

import { Button } from './form/Button';

export function CookieConsent() {
  const [, { setConsent }] = useConsent();

  return (
    <StyledConsent>
      We use cookies to offer you a better user experience. By using our website, you accept to our use of cookies
      policy.
      <StyledButton content="Got it" mode={ButtonMode.Blue} size={2} onClick={setConsent}/>
    </StyledConsent>
  );
}

const StyledConsent = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 1.5rem 1.5rem calc(1.5rem + env(safe-area-inset-bottom));
  color: var(--foreground);
  font-size: .85rem;
  text-align: center;
  z-index: 1010;
  ::before {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--navigation);
    opacity: .75;
    z-index: -1;
  }
`;

const StyledButton = styled(Button)`
  margin-top: 1.5rem;
  font-size: 1rem;
`;
