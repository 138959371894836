import { createDakpan } from 'dakpan';

type State = {
  current: number,
  tokens: string[],
  ignore: string[],
  roomNumbers: string[]
};

export const [AuthProvider, useAuth] = createDakpan<State>(() => {
  const tokens = JSON.parse(localStorage.getItem('tokens') || '[]');
  let current = parseInt(localStorage.getItem('current') || '', 10);
  const ignore = JSON.parse(localStorage.getItem('ignore') || '[]');
  const roomNumbers = JSON.parse(localStorage.getItem('roomNumbers') || '[]');

  if (isNaN(current)) {
    current = -1;
  }

  return {
    current: Math.min(tokens.length - 1, current),
    tokens,
    ignore,
    roomNumbers
  };
})({
  addToken: (token: string) => (state) => {
    const tokens = state.tokens.includes(token) ? state.tokens : [
      ...state.tokens,
      token
    ];
    const current = tokens.indexOf(token);
    const ignore = [
      ...state.ignore,
      token
    ]

    const roomNumbers = state.tokens.includes(token) ? state.roomNumbers : [
      ...state.roomNumbers,
      "0"
    ];

    localStorage.setItem('tokens', JSON.stringify(tokens));
    localStorage.setItem('current', String(current));
    localStorage.setItem('ignore', JSON.stringify(ignore));
    localStorage.setItem('roomNumbers', JSON.stringify(roomNumbers));

    return {
      ...state,
      current,
      tokens,
      ignore,
      roomNumbers
    };
  },
  removeToken: () => (state) => {
    const tokens = state.tokens.filter((_, i) => i !== state.current);
    const roomNumbers = state.roomNumbers.filter((_, i) => i !== state.current);
    const current = Math.min(tokens.length - 1, state.current);

    localStorage.setItem('tokens', JSON.stringify(tokens));
    localStorage.setItem('roomNumbers', JSON.stringify(roomNumbers));
    localStorage.setItem('current', String(current));

    return {
      ...state,
      current,
      tokens,
      roomNumbers
    };
  },
  setCurrent: (token: string) => (state) => {
    const current = state.tokens.indexOf(token);

    localStorage.setItem('current', String(current));

    return {
      ...state,
      current
    };
  },
  updateRoomNumbers: (rns: string[]) => (state) => {
    localStorage.setItem('roomNumbers',JSON.stringify(rns));

    return {
      ...state,
      roomNumbers: rns
    };
  }
});
